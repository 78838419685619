<template>
  <div>
    <VTitle :title="$t('app.lessons', 2)" class="route-title" />

    <div v-if="isLoading" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <div v-else>
      <div class="grid grid-cols-3 gap-8">
        <div class="col-span-3 lg:col-span-1">
          <LessonCard is-view :resource="data" />

          <Versions
            class="mt-8"
            :route="route"
            :versions="data?.lesson?.versions"
          />
        </div>

        <div class="col-span-3 lg:col-span-2">
          <LessonContent :resource="data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useLesson from "@/composables/useLesson";
import useRequest from "@/composables/useRequest";
// Components
import LessonCard from "@/views/learning/lessons/LessonCard";
import LessonContent from "@/views/learning/lessons/LessonContent";
import VTitle from "@/components/VTitle";
import Versions from "@/components/templates/Versions";
// Constants
import feedbackRatingTypes from "@/constants/feedbackRatingTypes";

export default {
  components: {
    VTitle,
    LessonCard,
    LessonContent,
    Versions
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();
    const { getText } = useTexts();
    const { route } = useLesson();

    // Data
    const data = ref({});
    const isLoading = ref(false);

    // Computed
    const lesson = computed(() => data.value?.lesson);
    const texts = computed(() => lesson.value?.texts);
    const name = computed(() => getText(texts.value, "name"));
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.lessons", 2)} - ${t("app.trainings", 2)} - ${t("app.registrations", 2)} - ${t("app.teaching")}`;

      return name.value ? `${name.value} - ${title}` : title;
    });

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "learning.lessons.read",
        pathParams: {
          id: props.id
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? {};

      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    return {
      name,
      data,
      isLoading,
      documentTitle,
      getData,
      feedbackRatingTypes,
      // useLesson
      route
    };
  }
};
</script>
